





































































import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    return {
      id: '',
      realName: '',
      phone: '',
      state: 0, //  0：已取消 1：待关联 2：已关联 3: 驳回
      type: 2, // 1:专家 2:烟农 3：合作社 4: 气象服务人员
      refuseTxt: '',
      show: false,
      options: [],
      fieldValue: '',
      cascaderValue: '',
      county: '',
      countyCode: '',
      town: '',
      townCode: '',
    }
  },
  mounted() {
    this.getData()
    this.getStationData()
  },
  methods: {
    onFinish({ selectedOptions }) {
      // console.log(selectedOptions)
      // selectedOptions.map((option) => {
      //     console.log(option)
      // })
      this.fieldValue = selectedOptions.map((option) => option.text).join('/')
      this.show = false
      this.county = selectedOptions[1].text
      this.countyCode = selectedOptions[1].value
      this.town = selectedOptions[2].text
      this.towncode = selectedOptions[2].value
    },
    getData() {
      request.get('/api/account/rest_manager_apply').then((res: any) => {
        if (!res || !res.id) return
        const { id, realName, phone, type, refuseTxt, state } = res
        this.id = id
        this.realName = realName
        this.phone = phone
        this.state = state
        this.type = type
        this.refuseTxt = refuseTxt
      })
    },
    getStationData() {
      request.get('/api/sys/area/all_list').then((res: any) => {
        // console.log(res)
        if (!res) return
        const areaList = [] as any
        const areaCity = [] as any
        const arrtown = [] as any
        res.map((e: any) => {
          e.text = e.name
          e.value = e.id
          if (!e.parentId) {
            areaList.push(e)
          } else if (String(e.id).length === 6) {
            areaCity.push(e)
          } else {
            arrtown.push(e)
          }
        })
        // console.log(areaList)
        // console.log(areaCity)
        // console.log(arrtown)
        areaCity.map((e: any) => {
          e.children = [] as any
          arrtown.map((i: any) => {
            if (e.id === i.parentId) {
              e.children.push(i)
            }
          })
        })
        areaList.map((e: any) => {
          e.children = areaCity
        })
        // console.log(areaList)
        this.options = areaList
      })
    },
    submit() {
      const { id, realName, phone, county, countyCode, town, towncode, type } = this
      const params = { realName, phone, county, countyCode, town, towncode, type }
      // console.log(params)
      const url = '/api/tobacco/rest_rel'
      if (id) {
        request.put(url, params)
          .then(() => this.getData())
          .catch((e: any) => this.$notify(e))
      } else {
        request.post(url, params)
          .then(() => this.getData())
          .catch((e: any) => this.$notify(e))
      }

    },
    onFailed(errorInfo: any) {
      console.log('failed', errorInfo);
    },
  }
})
